import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
// import { environment } from 'src/environments/environment';
// import { Email } from 'src/modells/email';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  age = "";
  currentYear = "";
  birthday = "2000-05-07";

  senderEmail = "";
  emailSubject = "";
  emailMessage = "";

  constructor(private http: HttpClient) { }
  
  ngOnInit(): void {
    this.age = this.getMyAge(this.birthday);
    this.currentYear = this.getCurrentYear();
  }

  getMyAge(startDate: string){
    var currentDate = new Date();
    var pastDate = new Date(startDate)
    var currentDateYear = currentDate.getFullYear();
    var currentDateMonth = currentDate.getMonth();
    var currentDateDay = currentDate.getDate();
    var pastDateYear = pastDate.getFullYear();
    var pastDateMonth = pastDate.getMonth();
    var pastDateDay = pastDate.getDate();
    if (currentDateMonth >= pastDateMonth){
      if ((currentDateMonth == pastDateMonth && currentDateDay >= pastDateDay) || (currentDateMonth > pastDateMonth)) {
        return (currentDateYear - pastDateYear).toString();
      }
      else {
        return (currentDateYear - pastDateYear - 1).toString();
      }
    }
    else {
      return (currentDateYear - pastDateYear - 1).toString();      
    }
  }

  getCurrentYear(){
    var date = new Date();
    return date.getFullYear().toString();
  }
}